module.exports = {
	customMessages: {
		DL_FRONT: {
			firstMessage: 'Center document on a dark background',
			fourCornerMessage: 'Scanning for document',
		},
		PDF417_BARCODE: {
			firstMessage: 'Center document on a dark background',
			fourCornerMessage: 'Scanning for barcode',
		},
		PASSPORT: {
			firstMessage: 'Center Passport on a dark background',
			fourCornerMessage: "Scanning for Passport's photo-page",
		},
		SELFIE: {
			firstMessage: 'Place face in oval',
			fourCornerMessage: 'Face not found',
		},
		CHECK_FRONT: {
			firstMessage: 'Center Check Front on a dark background',
			fourCornerMessage: 'Scanning for Check Front',
		},
		CHECK_BACK: {
			firstMessage: 'Center Check Back on a dark background',
			fourCornerMessage: 'Scanning for Check Back',
		},
		DOCUMENT: {
			firstMessage: 'Center document on a dark background',
			fourCornerMessage: 'Scanning for document',
		},
	},

	// These are hints that are recommended for displaying
	// to the end user during auto capture.
	autoCaptureHints: {
		MISNAP_HEAD_OUTSIDE: 'Place Face in Oval',
		MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
		MISNAP_AXIS_ANGLE: 'Hold Phone Upright',
		MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
		MISNAP_HEAD_TOO_FAR: 'Get Closer',
		MISNAP_STAY_STILL: 'Hold Still',
		MISNAP_SUCCESS: 'Success',
		MISNAP_STOP_SMILING: 'Stop Smiling',
		MISNAP_SMILE: 'Smile!',
		MISNAP_READY_POSE: 'Hold it There',
		NO_FACE_FOUND: 'Face not found',
		MITEK_ERROR_GLARE: 'Reduce Glare',
		MITEK_ERROR_FOUR_CORNER: 'Document Not Found',
		MITEK_ERROR_TOO_DARK: 'Too Dark. Use good lighting',
		MITEK_ERROR_FOCUS: 'Hold Steady',
		MITEK_ERROR_MRZ_MISSING: "Scanning for Passport's photo-page",
		CV_NO_BARCODE_FOUND: 'Scanning for barcode',
		MITEK_ERROR_TOO_FAR: 'Document Too Far',
		MITEK_ERROR_TOO_CLOSE: 'Document Too Close',
		MITEK_ERROR_NOT_CENTERED: 'Document Not Centered',
		MITEK_ERROR_MIN_PADDING: 'Move further away',
		MITEK_ERROR_HORIZONTAL_FILL: 'Move closer',
	},

	// Enum for the exceptions returned in the SDK_ERROR event.
	// eslint-disable-next-line no-unused-vars
	sdkErrors: {
		111: {
			message:
				'The video camera must support 720p resolution. Or, the app is not hosted via TLS',
			action: 'Move to a device with higher res video. Or, make sure the app is served over HTTPS',
		},
		112: {
			message: 'No video camera was found',
			action: 'Move to a device that has a video camera.',
		},
		113: {
			message: 'Camera Permission was Denied',
			action: 'Click "accept/allow" when prompted for camera permission.',
		},
		120: {
			message: 'Unable to start the camera / unknown error',
			action: 'Switch to manual capture mode.',
		},
		331: {
			message: 'An unknown command method was called',
			action: 'Check source code and correct any misspelled cmd key names.',
		},
		332: {
			message: 'Incorrect number of arguments passed to the cmd method',
			action: 'Check source code and correct any with a wrong argument count.',
		},
		333: {
			message: 'The getUserMedia method is not supported by the browser',
			action: 'Switch to manual capture mode.',
		},
		334: {
			message: 'Web Assembly is not supported by the browser',
			action: 'Switch to manual capture mode.',
		},
		335: {
			message: 'This device is not supported',
			action: 'Switch to manual capture mode.',
		},
		336: {
			message: 'This device does not support WebGL',
			action: 'Switch to manual capture mode.',
		},
		339: {
			message: 'This device does not support WebGL shader features',
			action: 'Switch to manual capture mode.',
		},
	},

	// This library can be used by the client code for displaying user freindly messages.
	// eslint-disable-next-line no-unused-vars
	generalHints: {
		MITEK_ERROR_FOUR_CORNER:
			"We can't find the 4 corners of your document.",
		MITEK_ERROR_TOO_DARK: 'There is not enough light on your document.',
		MITEK_ERROR_FOCUS: 'The image is too blurry.',
		MITEK_ERROR_GLARE: 'The image has glare.',
		MITEK_ERROR_MIN_PADDING:
			'Move the camera further away from your document.',
		MITEK_ERROR_HORIZONTAL_FILL: 'Move the camera closer to your document.',
		MITEK_ERROR_SKEW_ANGLE:
			'Document is skewed.  Hold camera directly over your document.',
		MITEK_ERROR_LOW_CONTRAST: 'Center your document on a dark background.',
		MITEK_ERROR_BUSY_BACKGROUND:
			'The background is too busy.  Please use a solid background.',
		MITEK_ERROR_MRZ_MISSING: 'No MRZ found',
		CV_NO_BARCODE_FOUND:
			'We were unable to detect the barcode from the back of your license.',
		IMAGE_SMALLER_THAN_MIN_SIZE: 'The image you provided is too small.',
		CORRUPT_IMAGE: 'The image you provided is unreadable.',
		MISNAP_HEAD_SKEWED: 'Look Straight Ahead',
		MISNAP_HEAD_TOO_CLOSE: 'Move Farther Away',
		MISNAP_HEAD_TOO_FAR: 'Get Closer',
		NO_FACE_FOUND: 'No Face Detected',
	},
};
