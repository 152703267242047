/* eslint-disable */
import React, { useState } from 'react';
import Gallery from './Gallery';
import DocTypeState from './DocTypeState.js';
import CaptureModeState from './CaptureModeState';
import AutoOptionsState from './AutoOptionsState';
import SdkOptionsState from './SdkOptionsState';

import './mitek/mitekSDK.css';
import sdkEnum from './mitek/sdkEnum';
import * as mitekScienceSDK from './mitek/mitek-science-sdk';

function App() {
	// ***************************************
	// BEGIN Application state declarations

	const dummyImage =
		'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/5+hHgAHggJ/PchI7wAAAABJRU5ErkJggg==';

	const [mode, setMode] = useState('AUTO_CAPTURE');
	const [subject, setSubject] = useState('SELFIE');

	// The gallery construct holds all the items (pictures) represented in the Gallery component.
	// There is one position for each of the docType subjects. Filled in the order they are captured.
	const [gallery, setGallery] = useState({
		pictures: Array(4).fill({
			docType: '',
			image: dummyImage,
			mibiData: {},
			decodedStr: '',
		}),
	});

	// UXP properties not intrinsically handled by the SDK CAPTURE_AND_PROCESS_FRAME options.
	const [autoCaptureOptions, setAutoCaptureOptions] = useState({
		timeoutSec: 20,
		showCancel: false,
	});

	// Initialize to default CAPTURE_AND_PROCESS_FRAME defaults
	const [sdkOptions, setSdkOptions] = useState({
		qualityPercent: 80,
		guidePaddingLevel: 1,
		hintFrequencyMS: 1200,
		hintMessageSize: 2,
		disableSmileDetection: false,
		videoContainerId: null,
		hintAriaLive: 2,
	});

	const [showSpinner, setShowSpinner] = useState(false);

	// END Application state declarations
	// ************************************

	const sdkResourcePath = `${process.env.PUBLIC_URL}/mitekSDK/`;
	let timerId = null;

	/**
	 * Returns the index of the item which has its docType property matching the arg doctype.
	 * If none exists, the index of the first empty item slot is returned.
	 * @param {Array} arr The gallery array of items to search.
	 * @param {string} doctype The value in the item.docType property to filter for.
	 */
	const getDocTypeIdx = (arr, doctype) => {
		let idx = arr.findIndex((item) => doctype === item.docType);
		return idx >= 0 ? idx : arr.findIndex((item) => '' === item.docType);
	};

	/**
	 * Catalogs the capture session results into a new or existing gallery.pictures array item.
	 * @param {object} result The output object from the SDK's FRAME_CAPTURE_RESULT event.
	 */
	const handleResult = (result) => {
		const picturesMut = [...gallery.pictures];

		picturesMut[getDocTypeIdx(picturesMut, subject)] = {
			docType: result.response.docType,
			image: result.response.imageData,
			mibiData: result.response.mibiData,
			decodedStr: result.response.code,
		};
		console.log('picturesMut', picturesMut);
		setGallery({ pictures: picturesMut });
	};

	const addCancelButton = () => {
		const mitekDisplayContainer = document.querySelector(
			'#mitekDisplayContainer'
		);

		// add a button to allow the user to cancel the session
		let buttonEl = document.createElement('button');
		buttonEl.setAttribute('id', 'mitekCancelButton');
		buttonEl.setAttribute(
			'style',
			'position: absolute; right: 15px; top: 15px; z-index: 100'
		);
		buttonEl.innerHTML = 'Cancel';
		buttonEl.onclick = (e) => {
			clearTimeout(timerId);
			mitekScienceSDK.cmd('SDK_STOP');
		};
		mitekDisplayContainer.appendChild(buttonEl);
	};

	const handleException = (excpt) => {
		console.log(excpt);
	};

	/**
	 * Invokes the SDK to start a Manual or Auto capture.
	 */
	const startCaptureSession = () => {
		/**
		 * AUTO_CAPTURE frames started processing. add any viewport layout elements
		 */
		mitekScienceSDK.on('FRAME_PROCESSING_STARTED', () => {
			console.log(
				'FRAME_PROCESSING_STARTED isCancelBtnVisible',
				autoCaptureOptions.showCancel
			);
			if (autoCaptureOptions.showCancel) {
				addCancelButton();
			}
		});

		/**
		 * AUTO_CAPTURE camera started
		 */
		mitekScienceSDK.on('CAMERA_DISPLAY_STARTED', () => {
			timerId = setTimeout(() => {
				clearTimeout(timerId);
				mitekScienceSDK.cmd('SDK_STOP');
				handleException({ message: 'TIMEOUT' });
			}, autoCaptureOptions.timeoutSec * 1000);

			// show the first initial hint message
			mitekScienceSDK.cmd(
				'SHOW_HINT',
				sdkEnum.customMessages[subject].firstMessage
			);
		});

		/**
		 * MANUAL_CAPTURE only. Show the spinner
		 */
		mitekScienceSDK.on('IMAGE_CAPTURED', () => {
			setShowSpinner(true);
		});

		/**
		 * AUTO_CAPTURE show hints to user
		 * MANUAL-CAPTURE handle bad input
		 */
		mitekScienceSDK.on('FRAME_PROCESSING_FEEDBACK', (status) => {
			if ('AUTO_CAPTURE' === mode) {
				if ('SELFIE' === subject) {
					const divFace =
						document.body.getElementsByClassName(
							'integrator SELFIE'
						);
					// turn oval green if head is in guide
					if (
						'MISNAP_SMILE' === status.Key ||
						'MISNAP_STOP_SMILING' === status.Key ||
						'MISNAP_READY_POSE' === status.Key
					) {
						divFace[0].classList.add('FACE_IN_GUIDE');
					} else {
						divFace[0].classList.remove('FACE_IN_GUIDE');
					}
					if (status.key !== null) {
						mitekScienceSDK.cmd(
							'SHOW_HINT',
							sdkEnum.autoCaptureHints[status.key]
						);
					}
				} else {
					if (status.key !== null) {
						mitekScienceSDK.cmd(
							'SHOW_HINT',
							'MITEK_ERROR_FOUR_CORNER' === status.key
								? sdkEnum.customMessages[subject]
										.fourCornerMessage
								: sdkEnum.autoCaptureHints[status.key]
						);
					}
				}
			}

			if ('MANUAL_CAPTURE' === mode) {
				// Only return exceptions that don't accompany a result
				// FRAME_CAPTURE_RESULT encloses the same object
				if (
					'CORRUPT_IMAGE' === status.key ||
					'IMAGE_SMALLER_THAN_MIN_SIZE' === status.key
				) {
					exception.list = [status];
					handleException(exception);
				}
			}
		});

		/**
		 * AUTO_CAPTURE clear the timeout object and call SDK_STOP
		 * MANUAL_CAPTURE hide spinner
		 */
		mitekScienceSDK.on('FRAME_CAPTURE_RESULT', (result) => {
			if ('AUTO_CAPTURE' === mode) {
				clearTimeout(timerId);
				mitekScienceSDK.cmd('SDK_STOP');
			}
			if ('MANUAL_CAPTURE' === mode) {
				console.log('Manual capture result', result);
				setShowSpinner(false);
			}
			handleResult(result);
		});

		mitekScienceSDK.cmd('CAPTURE_AND_PROCESS_FRAME', {
			mode: mode,
			documentType: subject,
			mitekSDKPath: sdkResourcePath,
			options: sdkOptions,
		});
	};

	return (
		<div className="container">
			<div className="card">
				<h3 className="card-header">
					MiSnap for Mobile Web SDK {mitekScienceSDK.getVersion()}
				</h3>
				<div className="card-body">
					<DocTypeState
						initialDocType={subject}
						onChange={setSubject}
					/>
					<CaptureModeState initialMode={mode} onChange={setMode} />
					<AutoOptionsState
						initialTimeout={autoCaptureOptions.timeoutSec}
						initialShowCancel={autoCaptureOptions.showCancel}
						onChange={setAutoCaptureOptions}
					/>
					<SdkOptionsState
						sdkOptions={sdkOptions}
						onChange={setSdkOptions}
					/>
				</div>

				<div className="card-footer">
					<button
						className="btn btn-primary"
						onClick={startCaptureSession}
					>
						Get Image
					</button>
				</div>
			</div>

			<div className="card">
				<h4 className="card-header">Capture Gallery</h4>
				<Gallery captures={gallery.pictures} />
			</div>

			<div
				className="loader-wrap"
				id="main-spinner"
				style={{ display: showSpinner ? 'block' : 'none' }}
			>
				<div className="loader"></div>
			</div>
		</div>
	);
}

export default App;
